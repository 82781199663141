import React, {use, useState} from 'react';
import classes from "./main.module.css";
import Header from "../../common/header/header";
import fini from '../../../image/fini1.png'
import coin from '../../../image/coin.png'
import Button from "../../common/button/button";
import Menu from "../../common/menu/menu";
import Settings from "../../common/settings/settings";
import Referrals from "../../common/refferals/referrals";
import Tasks from "../../common/tasks/tasks";
import Game from "../../common/game/game";

const Main = () => {
    const [balance, setBalance] = useState(5000)
    const [selectMenu, setSelectMenu] = useState(1)

    return (
        <div className={classes._}>
            <div>
                {(selectMenu===1 || selectMenu===2) && <Header energy={10} level={1} part={30}/>}
                {selectMenu===1 && <div className={classes.balance}>
                    <img src={coin} alt={''} className={classes.coin}/>
                    {balance}
                </div>}
                {selectMenu===2 &&
                    <Referrals/>
                }
                {selectMenu===3 &&
                    <Tasks/>
                }
            </div>
            {selectMenu===1 && <img src={fini} alt={''} className={classes.fini}/>}
            {selectMenu !==5 && <div className={classes.block}>
                {selectMenu===1 && <div className={classes.blockButton}>
                    <Button active={true} onClick={()=>setSelectMenu(5)}>Играть</Button>
                </div>}
                <Menu selectMenu={selectMenu} setSelectMenu={setSelectMenu}/>
            </div>}
            {selectMenu===5 && <Game/>}
        </div>
    );
};

export default Main;