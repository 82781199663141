import Start from "./components/pages/start/start";
import FirstStart from "./components/pages/firstStart/firstStart";
import {useEffect, useState} from "react";
import {CSSTransition} from "react-transition-group";
import Main from "./components/pages/main/main";


function App() {
    const [openStart, setOpenStart] = useState(true)
    const [openFirstStart, setOpenFirstStart] = useState(false)
    const [openMain, setOpenMain] = useState(false)

    console.log('Version 0.3')

    return (
        <div className="App">
            <CSSTransition in={openStart}
                           timeout={500}
                           classNames="openBlock"
                           unmountOnExit
                           onExited={()=>setOpenFirstStart(true)}
            >
                <Start setOpenStart={setOpenStart}/>
            </CSSTransition>
            <CSSTransition in={openFirstStart}
                           timeout={500}
                           classNames="openBlock"
                           unmountOnExit
                           onExited={()=>setOpenMain(true)}
            >
                <FirstStart setOpenFirstStart={setOpenFirstStart}/>
            </CSSTransition>
            <CSSTransition in={openMain}
                           timeout={500}
                           classNames="openBlock"
                           unmountOnExit
            >
                <Main/>
            </CSSTransition>
        </div>
    );
}

export default App;
