import React, {useEffect, useState} from 'react';
import classes from "./start.module.css";

const Start = ({setOpenStart}) => {
    const [text, setText] = useState({
        text:'Будущее принадлежит тем, кто верит в свои мечты и инвестирует в них',
        name:'Франклин Рузвельт'
    })

    useEffect(()=>{
        setTimeout(()=>setOpenStart(false),3000)
    },[])

    return (
        <div className={classes._} onClick={()=>setOpenStart(false)}>
            <div className={classes.text1}>{text.text}</div>
            <div className={classes.text2}>{text.name}</div>
        </div>
    );
};

export default Start;