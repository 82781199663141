import React from 'react';
import classes from "./button.module.css";

const Button = (props) => {
    return (
        <button className={classes._+' '+(!props.active && classes.non)}
                onClick={props.active ? props.onClick: ()=>{}}
                style={props.style}
        >
            {props.children}
        </button>
    );
};

export default Button;