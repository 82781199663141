import React, {useEffect, useState} from 'react';
import classes from "./quiz.module.css";
import {CSSTransition} from "react-transition-group";
import Button from "../button/button";

const Quiz = ({textTop, item, open, next}) => {
    const [select, setSelect] = useState({id:0, text:''})

    async function answer(){
        next()
    }

    return (
        <CSSTransition in={open}
                       timeout={500}
                       classNames="openBlock"
                       unmountOnExit
        >
            <div className={classes._}>
                <div className={classes.block}>
                    <div className={classes.textTop}>{textTop}</div>
                    <div className={classes.question}>{item.question}</div>
                    <div className={classes.answerBlock}>
                        {item.answer.map(i =>
                            <div key={i.id} className={classes.answer} onClick={()=>setSelect(i)}>
                                <div>{i.text}</div>
                                <div className={classes.answerPoint+' '+(select.id===i.id && classes.answerPointCheck)}>
                                    <div className={classes.answerPoint2+' '+(select.id===i.id && classes.answerPointCheck2)}></div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <Button active={select.id !== 0} onClick={answer}>Ответить</Button>
            </div>
        </CSSTransition>
    );
};

export default Quiz;