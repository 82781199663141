import React, {useEffect, useRef, useState} from 'react';
import classes from "./game.module.css";
import background from './game.svg'

const Game = () => {
    const ref = useRef()
    const [levels, setLevels] = useState([
        {id:1, status:'open', result:0}
    ])

    useEffect(()=>{
        //setTimeout(()=>{window.scrollTo(0, ref.current.scrollHeight)}, 1000);
        let temp = [{id:1, status:'open', result:0}]
        for(let i=0; i<150; i++){
            temp.push({id:i+2, status:'close', result:0})
        }
        setLevels(temp)

    },[])

    const [scrollRatio, setScrollRatio] = useState(0);


    return (
        <div className={classes._}>
            <div
                className={classes.backgroundContainer}
                style={{
                    transform: `translateY(${scrollRatio * -200}%)`,
                }}
            >
                <img alt={''} src={background} className={classes.background}/>
                <img alt={''} src={background} className={classes.background}/>
            </div>
            <div className={classes.block} onScroll={(e) => {
                const scrollTop = e.target.scrollTop; // Позиция скролла контейнера
                const scrollHeight = e.target.scrollHeight; // Общая высота контента
                const clientHeight = e.target.clientHeight; // Высота видимой области
                const ratio = scrollTop / (scrollHeight - clientHeight); // Пропорция скролла
                console.log(scrollTop,scrollHeight, clientHeight, ratio*0.45)
                setScrollRatio(ratio*0.45);
            }}>
                {levels.map((item, number)=>
                    <div className={classes.level} key={item.id}>
                        {number}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Game;