import React, {useState} from 'react';
import classes from "./tasks.module.css";

const Tasks = () => {
    const [dailyTasks, setDailyTasks] = useState([
        {id:1, text:'Награда за ежедневный вход раз в три час', price:'20 000'},
        {id:2, text:'Пройти 1 квиз', price:'20 000'},
    ])

    const [allTasks, setAllTasks] = useState([
        {id:1, text:'Подпишись на канал Создай капитал с Азатом Валеевым', price:'20 000'},
        {id:2, text:'Составь свой финансовый план', price:'20 000'},
    ])

    return (
        <div className={classes._}>
            <div className={classes.block}>
                <div className={classes.text1}>Ежедневный бонус</div>
                {dailyTasks.map(item=>
                    <div key={item.id} className={classes.task}>
                        <div className={classes.taskText}>{item.text}</div>
                        <div className={classes.taskBlock}>
                            <div className={classes.taskPrice}>+{item.price}</div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M10.0909 9.00004L5.79039 5.05786C5.36543 4.66831 5.36543 3.99835 5.7904 3.60881C6.16617 3.26435 6.74293 3.26435 7.1187 3.60881L13 9.00004L7.1187 14.3912C6.74293 14.7357 6.16618 14.7357 5.7904 14.3912C5.36544 14.0017 5.36544 13.3317 5.79041 12.9421L10.0909 9.00004Z" fill="#AE9367"/>
                            </svg>
                        </div>
                    </div>
                )}
            </div>
            <div className={classes.block}>
                <div className={classes.text1}>Финкоинов много не бывает</div>
                {allTasks.map(item=>
                    <div key={item.id} className={classes.task}>
                        <div className={classes.taskText}>{item.text}</div>
                        <div className={classes.taskBlock}>
                            <div className={classes.taskPrice}>+{item.price}</div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M10.0909 9.00004L5.79039 5.05786C5.36543 4.66831 5.36543 3.99835 5.7904 3.60881C6.16617 3.26435 6.74293 3.26435 7.1187 3.60881L13 9.00004L7.1187 14.3912C6.74293 14.7357 6.16618 14.7357 5.7904 14.3912C5.36544 14.0017 5.36544 13.3317 5.79041 12.9421L10.0909 9.00004Z" fill="#AE9367"/>
                            </svg>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Tasks;