import React from 'react';
import classes from "./popup.module.css";
import {CSSTransition} from "react-transition-group";

const Popup = (props) => {
    return (
        <>
            <CSSTransition in={props.open}
                           timeout={300}
                           classNames="openBlock"
                           unmountOnExit
            >
                <div className={classes.fon} onClick={props.close}></div>
            </CSSTransition>
            <CSSTransition in={props.open}
                           timeout={300}
                           classNames="openBonusMenu"
                           unmountOnExit
            >
                <div className={classes._}>
                    <div className={classes.close} onClick={props.close}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M11.9997 10.5865L16.2424 6.34382C16.6329 5.9533 17.2661 5.9533 17.6566 6.34382C18.0471 6.73435 18.0471 7.36751 17.6566 7.75803L13.4139 12.0007L17.6566 16.2433C18.0471 16.6338 18.0471 17.267 17.6566 17.6575C17.2661 18.048 16.6329 18.048 16.2424 17.6575L11.9997 13.4149L7.75706 17.6575C7.36654 18.048 6.73338 18.048 6.34286 17.6575C5.95233 17.267 5.95233 16.6338 6.34286 16.2433L10.5855 12.0007L6.34285 7.75804C5.95232 7.36751 5.95233 6.73435 6.34285 6.34382C6.73338 5.9533 7.36654 5.9533 7.75707 6.34383L11.9997 10.5865Z" fill="#AE9367"/>
                        </svg>
                    </div>
                    {props.children}
                </div>
            </CSSTransition>
        </>
    );
};

export default Popup;