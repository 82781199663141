import React, {useState} from 'react';
import classes from "./header.module.css";
import energyImage from '../../../image/energy.png'
import Settings from "../settings/settings";
import {useTelegram} from "../../../hooks/useTelegram";

const Header = ({level, part, energy}) => {
    const [openSettings, setOpenSettings] = useState(false)
    const {user} = useTelegram()

    return (
        <div className={classes._}>
            <div className={classes.block1}>
                <div className={classes.block1_1} style={{width:`${part}%`}}></div>
                <div className={classes.block1Text}>Уровень {level}</div>
            </div>
            <div className={classes.block2}>
                <img alt={''} src={energyImage} className={classes.energy}/>
                {energy}/10
            </div>
            <div className={classes.block3} onClick={()=>setOpenSettings(true)}>
                {user?.photo_url ?
                    <img alt={''} src={user?.photo_url} className={classes.avatar}/> :
                    <div>{user?.name[0]}</div>
                }
            </div>
            <Settings open={openSettings} close={()=>setOpenSettings(false)}/>
        </div>
    );
};

export default Header;