import React, {use, useState} from 'react';
import classes from "./settings.module.css";
import Popup from "../popup/popup";
import {useTelegram} from "../../../hooks/useTelegram";
import Button from "../button/button";
import {CSSTransition} from "react-transition-group";

const Settings = ({open, close}) => {
    const {user} = useTelegram()
    const [infoCheck, setInfoCheck] = useState(false)
    const [openInfo, setOpenInfo] = useState(false)
    const [valueName, setValueName] = useState("")
    const [valueTel, setValueTel] = useState("")
    const [valueEmail, setValueEmail] = useState("")

    async function saveInfo(){
        setOpenInfo(false)
        setInfoCheck(true)
    }

    return (
        <Popup open={open} close={close}>
            <div className={classes.avatarBlock}>
                {user?.photo_url ?
                    <img alt={''} src={user?.photo_url} className={classes.avatar}/> :
                    <div className={classes.avatarNone}>{user?.name[0]}</div>
                }
            </div>
            <div className={classes.buttonBlock}>
                <div className={classes.button}>Связь с поддержкой</div>
                <div className={classes.button}>Политика конфиденциальности</div>
                <div className={classes.button}>Заказать приложение</div>
                {infoCheck && <div className={classes.button} onClick={()=>setOpenInfo(true)}>Изменить данные</div>}
            </div>
            {!infoCheck &&
                <Button active={true} onClick={()=>setOpenInfo(true)}>Войти</Button>
            }
            <CSSTransition in={openInfo}
                           timeout={300}
                           classNames="openBlock"
                           unmountOnExit
            >
                <div className={classes.popup}>
                    <div>
                        <div className={classes.avatarBlockBig}>
                            {user?.photo_url ?
                                <img alt={''} src={user?.photo_url} className={classes.avatar+' '+classes.avatarBig}/> :
                                <div className={classes.avatarNone+' '+classes.avatarBig}>{user?.name[1]}</div>
                            }
                        </div>
                        <div className={classes.inputBlock}>
                            <input className={classes.input}
                                   placeholder={"Ваше имя"}
                                   value={valueName}
                                   onChange={e=>setValueName(e.target.value)}
                            />
                            <input className={classes.input}
                                   placeholder={"Ваш телефон"}
                                   value={valueTel}
                                   onChange={e=>setValueTel(e.target.value)}
                            />
                            <input className={classes.input}
                                   placeholder={"Почта"}
                                   value={valueEmail}
                                   onChange={e=>setValueEmail(e.target.value)}/>
                        </div>
                    </div>
                    {infoCheck ?
                        <Button active={valueName !== "" && valueTel !== "" && valueEmail !== ""} onClick={saveInfo}>Сохранить</Button>:
                        <Button active={valueName !== "" && valueTel !== "" && valueEmail !== ""} onClick={saveInfo}>Войти</Button>
                    }
                </div>
            </CSSTransition>
        </Popup>
    );
};

export default Settings;